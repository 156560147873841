import useStyles from "./styles";
import Box from "@material-ui/core/Box";
import LogoImg from "./images/smh-logo@2x.png";
import Typography from "@material-ui/core/Typography";
import React from "react";

function Header(props) {
    const classes = useStyles();

    return (
        <div>
            <Box align="center">
                <img src={LogoImg} alt="Smart Home" className={classes.logo}/>
            </Box>
            <Box mb={2}>
                <Typography className={classes.signtitletext} component="h5" variant="h5" align="center">
                    {props.name}
                </Typography>
            </Box>
        </div>
    )
}

export default Header;