import TextField from "@material-ui/core/TextField";
import React, {useState} from "react";
import * as common from "./mask";
import useStyles from "./styles";

function LoginField(props) {
    const parentOnChangeLogin = props.onChangeLogin
    const classes = useStyles();
    const [isPhone, setIsPhone] = useState(false);
    const loginStorage = localStorage.getItem('login')
    const [login, setLogin] = useState(loginStorage == null ? '' : loginStorage);
    parentOnChangeLogin(login)

    function onLoginChange(e) {
        const values = common.maskLogin(e.target.value, login, isPhone)
        e.target.value = values[0]
        parentOnChangeLogin(values[0])
        setLogin(values[0])
        setIsPhone(values[1])
    }

    return (    <TextField
        error={props.error !== ''}
        helperText={props.showErrorText ? props.error : ""}
        variant="outlined"
        margin="dense"
        autoFocus={props.autoFocus}
        fullWidth
        id="login"
        label="Телефон / Триколор ID"
        name="login"
        autoComplete="login"
        inputProps={{
            maxLength: isPhone ? 18 : 18
        }}
        value={login}
        className={classes.inputtext}
        onChange={e => onLoginChange(e)}
    />)
}

export default LoginField;