import React, {useState} from 'react';
import Header from './header'
import useStyles from './styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import theme from "./common";
import Container from '@material-ui/core/Container'
import {ThemeProvider} from "@material-ui/core/styles"
import {useHistory} from 'react-router-dom'
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';
import axios from "axios";
import LoginField from "./loginField";
import * as common from './mask'


function Password() {
    let history = useHistory()
    const classes = useStyles();
    const log = localStorage.getItem('login')
    const [login, setLogin] = useState(log != null ? log : "");
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');

    async function onSendSms(e) {
        e.preventDefault()

        try {
            await axios.post("/api/v1/search", { login: common.normalizeLogin(login) })
            const response = await axios.post("/api/v1/send-code", { login: common.normalizeLogin(login) })
            localStorage.setItem('login', login)
            localStorage.setItem('phone', response.data.phone)
            history.push('/sms')
        } catch (err) {
            if (err.response.data.code === 3127) {
                localStorage.setItem('login', login)
                localStorage.setItem('error', err.response.data.message)
                history.push('/sms')
            } else {
                setError(err.response.data.message)
            }
        }
    }

    async function onLoginByPassword(e) {
        e.preventDefault()

        try {
            await axios.post("/api/v1/login",
                { login: common.normalizeLogin(login), password: password })
            history.push('/auth')
        } catch (err) {
            setError(err.response.data.message)
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="lg" disableGutters="true">

                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '100vh' }}
                >

                    <Grid item xs={12}>
                        <div className={classes.paper}>
                            <Header name="Авторизация"/>

                            <Typography className={classes.signtext} variant="body1" color="textSecondary" align="center">
                                Используйте данные с аккаунта <br/> “Триколор Умный дом”
                            </Typography>
                            <form className={classes.form}>
                                <Box mb={1} mt={2}>
                                    <LoginField onChangeLogin={value => setLogin(value)} error={error} showErrorText={false} autoFocus={false}/>
                                    <TextField
                                        error={error !== ''}
                                        id="outlined-adornment-password"
                                        margin="dense"
                                        fullWidth
                                        className={clsx(classes.margin, classes.inputtext)}
                                        variant="outlined"
                                        type={showPassword ? 'text' : 'password'}
                                        label="Пароль"
                                        value={password}
                                        helperText={error}
                                        autoFocus
                                        onChange={e => setPassword(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        className={classes.inputtext}
                                                        onMouseDown={e => {e.preventDefault()}}
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>
                                <Box mt={8} align="center" >
                                    <Button
                                        align="left"
                                        variant="text"
                                        color="primary"
                                        className={classes.submitbypass}
                                        onClick={e => {onSendSms(e)}}
                                    >
                                        Войти по СМС
                                    </Button>
                                    <Button
                                        type="submit"
                                        align="right"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submitbypass}
                                        onClick={e => {onLoginByPassword(e)}}
                                    >
                                        Войти
                                    </Button>
                                </Box>
                            </form>
                        </div>
                    </Grid>
                    <Box>
                    </Box>
                </Grid>

            </Container>
        </ThemeProvider>
    );
}

export default Password;