import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import Header from './header'
import useStyles from './styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import theme from "./common";
import Container from '@material-ui/core/Container'
import { ThemeProvider } from "@material-ui/core/styles"
import {useHistory} from "react-router-dom";
import axios from "axios";
import LoginField from "./loginField";
import * as common from "./mask";


function SmsCode() {
    let history = useHistory()
    const classes = useStyles();
    const [phone, setPhone] = useState(localStorage.getItem('phone'))
    const log = localStorage.getItem('login')
    const [login, setLogin] = useState(log != null ? log : "");
    const err = localStorage.getItem('error')
    localStorage.removeItem('error')
    const [error, setError] = useState(err != null ? err : "");
    const [timeLeft, setTimeLeft] = useState(60);
    const smsCodeLen = useRef(null)
    const smsCodeDelay = useRef(null)

    useEffect(() => {
        let interval = setInterval(() => {
            if (timeLeft === 0) {
                clearInterval(interval)
                return
            }
            setTimeLeft(timeLeft - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [timeLeft]);

    useLayoutEffect(() => {
        if (smsCodeDelay.current == null) {
            axios.get('/api/v1/sms/config')
                .then(res => {
                    smsCodeLen.current = res.data.sms_code_len;
                    smsCodeDelay.current = res.data.sms_code_send_delay;
                    setTimeLeft(smsCodeDelay.current)
                })
        }
    })

    async function onChangeCode(e) {
        e.preventDefault()

        if (e.target.value.length === smsCodeLen.current) {
            try {
                await axios.post("/api/v1/check-code", { login: common.normalizeLogin(login), code: e.target.value })
                history.push('/auth')
            } catch (err) {
                setError(err.response.data.message)
            }
        }
    }

    function onEnterByPassword(e) {
        e.preventDefault()

        localStorage.setItem('login', login)

        history.push('/password')
    }

    async function onRepeatSendCode(e) {
        e.preventDefault()
        try {
            const response = await axios.post("/api/v1/send-code", { login: common.normalizeLogin(login) })
            setPhone(response.data.phone)
        } catch (err) {
            setError(err.response.data.message)
        }
        setTimeLeft(smsCodeDelay.current)
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="lg" disableGutters="true">

                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '100vh' }}
                >

                    <Grid item xs={12}>
                        <div className={classes.paper}>
                            <Header name="Авторизация"/>
                            <Typography  className={classes.signtext} variant="body1" color="textSecondary" align="center">
                                На номер телефона {phone} был отправлен СМС-код
                            </Typography>
                            <form className={classes.form}>
                                <Box mb={1} mt={2}>
                                    <LoginField onChangeLogin={value => setLogin(value)} error={error} showErrorText={false} autoFocus={false}/>
                                    <TextField
                                        error={error !== ''}
                                        fullWidth
                                        variant="outlined"
                                        margin="dense"
                                        id="code"
                                        label="Код из СМС"
                                        helperText={error}
                                        autoFocus
                                        className={classes.inputtext}
                                        onChange={e => onChangeCode(e)}
                                    />
                                </Box>
                                {timeLeft !== 0 ?
                                    <Typography
                                        classname={classes.signtext}
                                        margin="normal"
                                        gutterBottom
                                        align="center"
                                        variant="body1"
                                        component="body"
                                        color="textSecondary"
                                    >
                                    Отправить код еще раз через {timeLeft} сек.
                                    </Typography>
                                    :
                                    <Box mt={1} align="center">
                                    <Button
                                        type="submit"
                                        align="center"
                                        variant="text"
                                        color="primary"
                                        className={classes.submitbypass}
                                        onClick={(e) => onRepeatSendCode(e)}
                                    >
                                        Отправить код еще раз
                                    </Button>
                                </Box>
                                }
                                <Box mt={8} align="center">
                                    <Button
                                        type="submit"
                                        align="center"
                                        variant="text"
                                        color="primary"
                                        className={classes.submitbypass}
                                        onClick={(e) => onEnterByPassword(e)}
                                    >
                                        Войти по паролю
                                    </Button>
                                </Box>
                            </form>
                        </div>
                    </Grid>
                    <Box>
                    </Box>
                </Grid>

            </Container>
        </ThemeProvider>
    );
}

export default SmsCode;