import React, {useState} from 'react';
import Header from './header'
import useStyles from './styles'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import theme from "./common";
import Container from '@material-ui/core/Container'
import {ThemeProvider} from "@material-ui/core/styles"
import {useHistory} from 'react-router-dom'
import LoginField from "./loginField";
import axios from "axios";
import * as common from './mask'


function Main() {
    let history = useHistory()
    const classes = useStyles();
    const [login, setLogin] = useState('');
    let err = localStorage.getItem('error')
    localStorage.clear()
    const [error, setError] = useState(err != null ? err : "");

    async function onSendSms(e) {
        e.preventDefault()

        try {
            await axios.post("/api/v1/search", { login: common.normalizeLogin(login) })
            const response = await axios.post("/api/v1/send-code", { login: common.normalizeLogin(login) })
            localStorage.setItem('login', login)
            localStorage.setItem('phone', response.data.phone)
            history.push('/sms')
        } catch (err) {
            if (err.response.data.code === 3127) {
                localStorage.setItem('login', login)
                localStorage.setItem('error', err.response.data.message)
                history.push('/sms')
            } else {
                setError(err.response.data.message)
            }
        }
    }

    async function onEnterByPassword(e) {
        e.preventDefault()

        try {
            await axios.post("/api/v1/search", { login: common.normalizeLogin(login) })
            localStorage.setItem('login', login)
            history.push('/password')
        } catch (err) {
            setError(err.response.data.message)
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="lg" disableGutters="true">

                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '100vh' }}
                >

                    <Grid item xs={12}>
                        <div className={classes.paper}>
                            <Header name="Авторизация"/>

                            <Typography className={classes.signtext} variant="body1" color="textSecondary" align="center">
                                Используйте данные с аккаунта <br/> “Триколор Умный дом”
                            </Typography>
                            <form className={classes.form}>
                                <Box mb={1} mt={2}>
                                    <LoginField onChangeLogin={value => setLogin(value)} error={error} showErrorText={true} autoFocus={true}/>
                                </Box>
                                <Box align="center">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}
                                        onClick={e => {onSendSms(e)}}
                                    >
                                        Получить код в SMS
                                    </Button>
                                </Box>
                                <Box mt={8} align="center">
                                    <Button
                                        type="submit"
                                        align="center"
                                        variant="text"
                                        color="primary"
                                        className={classes.submitbypass}
                                        onClick={e => {onEnterByPassword(e)}}
                                    >
                                        Войти по паролю
                                    </Button>
                                </Box>
                            </form>
                        </div>
                    </Grid>
                    <Box>
                    </Box>
                </Grid>

            </Container>
        </ThemeProvider>
    );
}

export default Main;