import {createMuiTheme} from "@material-ui/core/styles";
import {blue} from "@material-ui/core/colors";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#5BB4F0',
            contrastText: '#fff'
        },
        secondary: {
            main: blue[500],
        }
    }
});

export default theme;