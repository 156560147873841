import React, {useState} from 'react';
import Header from './header'
import useStyles from './styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import theme from "./common";
import Container from '@material-ui/core/Container'
import {ThemeProvider} from "@material-ui/core/styles"
import {useHistory} from 'react-router-dom'
import axios from "axios";


function getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function Main() {
    let history = useHistory()
    const classes = useStyles();
    const [appName] = useState(getCookie('client_name'));

    async function onAllowAuthorize(e) {
        e.preventDefault()

        try {
            await axios.post("/api/v1/allow/authorize", null)
            window.location.href = '/api/v1/oauth/authorize'
        } catch (err) {
            localStorage.setItem('error', err.response.data.message)
            history.push('/')
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="lg" disableGutters="true">

                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justify="center"
                    style={{ minHeight: '100vh' }}
                >

                    <Grid item xs={12}>
                        <div className={classes.paper}>
                            <Header name="Здравствуйте"/>

                            <Typography className={classes.signtext} variant="body1" color="textSecondary" align="center">
                                Приложению “{appName}” требуются права для управления вашими устройствами
                            </Typography>
                            <form className={classes.form}>

                                <Box mt={8} align="center">
                                    <Button
                                        type="submit"
                                        align="center"
                                        variant="contained"
                                        color="primary"
                                        className={classes.submitbypass}
                                        onClick={e => onAllowAuthorize(e)}
                                    >
                                        Предоставить
                                    </Button>
                                </Box>
                            </form>
                        </div>
                    </Grid>
                    <Box>
                    </Box>
                </Grid>

            </Container>
        </ThemeProvider>
    );
}

export default Main;