import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    body: {
        margin: '2px',
    },
    root: {
        paddingLeft: '0px',
        paddingRight: '0px',
    },
    paper: {
        display: 'flex',
        padding: '24px',
        margin: '0',
        border: '1px solid #ccc',
        borderRadius: '8px',
        flexDirection: 'column',
    },
    logo: {
        marginTop: '8px',
        marginBottom: '12px',
        height: '50px',
        width: '50px',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        height: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1,4,1,4),
        height: '36px',
    },
    submitbypass: {
        padding: theme.spacing(1,4,1,4),
        height: '36px',
    },
    signtext: {
        marginTop: '20px',
        fontSize: '14px',
    },
    signtitletext: {
        fontSize: '24px',
        fontWeight: '500',
        lineHeight: '28px'
    },
    inputtext: {
        fontSize: '14px',
    },
    linkbottom: {
        padding: '8px',
    },
}));

export default useStyles;