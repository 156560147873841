import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Main from "./Main";
import SmsCode from "./SmsCode";
import Password from "./Password";
import Auth from "./Auth";

function RouteComp() {
    return (
        <Router>
            <div>
                {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                <Switch>
                    <Route path="/sms">
                        <SmsCode />
                    </Route>
                    <Route path="/password">
                        <Password />
                    </Route>
                    <Route path="/auth">
                        <Auth />
                    </Route>
                    <Route path="/">
                        <Main />
                    </Route>
                </Switch>
            </div>
        </Router>
    );
}

export default RouteComp;