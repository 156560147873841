
const maskLogin = (formValue, login, isPhone) => {
    if (formValue.length > login.length) {
        const val = formValue
        if (val.length === 1) {
            const sym = val.charAt(0);
            if (sym === '+' || sym === '7' || sym === '8' || sym === '9') {
                isPhone = true
                formValue = "+7 ("
                if (sym === '9') {
                    formValue = formValue + "9"
                }
            } else {
                isPhone = false
            }
        } else if (val.length === 7 && isPhone) {
            formValue =  formValue + ") "
        } else if (val.length === 12 && isPhone) {
            formValue =  formValue + "-"
        } else if (val.length === 15 && isPhone) {
            formValue =  formValue + "-"
        } else if (!isPhone && (val.length === 3 || val.length === 7 || val.length === 11 || val.length === 15)) {
            formValue =  formValue + " "
        }
    }
    return [formValue, isPhone]
}

function normalizeLogin(login) {
    if (login.length === 0) {
        return login
    }

    let normalizeLogin = login.replaceAll(" ", "");
    if (login.charAt(0) === '+') {
        normalizeLogin = normalizeLogin
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll("-", "")
            .replaceAll("+", "");
        normalizeLogin = normalizeLogin.substring(1);
    }

    return normalizeLogin
}

export {
    maskLogin,
    normalizeLogin
};